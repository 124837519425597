// MessageBarContext.js
import React, { createContext, useState, useContext } from 'react';

const MessageBarContext = createContext();

export const useMessageBar = () => useContext(MessageBarContext);

export const MessageBarProvider = ({ children }) => {
  const [messageBarHeight, setMessageBarHeight] = useState(0); // Store height
  const updateMessageBarHeight = (height) => { 
    setMessageBarHeight(height);
  };

  return (
    <MessageBarContext.Provider value={{  messageBarHeight, updateMessageBarHeight }}>
      {children}
    </MessageBarContext.Provider>
  );
};
