import React, { useRef, memo, useState, useEffect } from "react";
import {Box, Stack, Typography } from "@mui/material";
import api from "../util/api";
import ReactHtmlParser from 'html-react-parser'
import { useMessageBar } from "../context/MessageBarContext";

const MessageBar = () => {    
    const [message, setMessage] = useState();
    const messageBarRef = useRef(null);
    const { updateMessageBarHeight } = useMessageBar();
    
    // Helper function to get the ordinal suffix (<sup> tag)
    const getOrdinalSuffixWithSup = (day) => {
        const suffixes = ['th', 'st', 'nd', 'rd'];
        const value = day % 100;
        const suffix = (value > 10 && value < 14) ? 'th' : suffixes[(value % 10)] || 'th';
        return `<sup>${suffix}</sup>`;
    };

   // Function to format the date
    const formatDateWithDayAndSup = (dateString) => {
        // Manually parse the date string to avoid time zone shifts
        const [year, month, day] = dateString.split('-').map(num => parseInt(num, 10));

        // Create a new date object in local time (not UTC)
        const date = new Date(year, month - 1, day);  // Month is zero-indexed in JavaScript

        // Get the full name of the day (e.g., "Thursday")
        const dayOfWeek = date.toLocaleString('default', { weekday: 'long' });
        
        // Get the full name of the month (e.g., "November")
        const monthName = date.toLocaleString('default', { month: 'long' });
        
        // Get the day of the month (e.g., 24)
        const dayOfMonth = date.getDate();
        
        // Get the ordinal suffix with <sup> tags
        const suffix = getOrdinalSuffixWithSup(dayOfMonth);
        
        // Return the formatted date with the day of the week, month, and day with <sup> for ordinal
        return `${dayOfWeek}, ${monthName} ${dayOfMonth}${suffix}`;
    };

    const formatMessage = (startDate, endDate, reopenDate, holiday) => {
        const startFormatted = formatDateWithDayAndSup(startDate);
        const reopenFormatted = formatDateWithDayAndSup(reopenDate);
        let msg = "PCS will be closed on ";
        
        // Check if the start and end date are the same
        if (startDate === endDate) {
          msg += `${startFormatted} `;
        } else {
          const endFormatted = formatDateWithDayAndSup(endDate);
          msg += `${startFormatted} - ${endFormatted} `;
        }

        msg += `in observance of ${holiday}. We will reopen normal business hours on ${reopenFormatted}.`
        return msg;
    }

    useEffect(() => {

        const controller = new AbortController();

        const fetchCategory = async () => {
            try {
                const response = await api.get(`/holiday-message`, {}, { signal: controller.signal });
                if (response.status === 200) {
                    if(response.data){
                        const {start_date, end_date, reopen_date, holiday} = response.data;
                        const message = formatMessage(start_date, end_date, reopen_date, holiday);                           
                        setMessage(message);  
                    }        
                } else {
                    console.error('Failed to fetch holiday:', response.statusText);
                }
            } catch (error) {
                console.error('Error fetching holiday:', error);
            }
        };
        
        fetchCategory();
        
        return () => controller.abort(); // Clean up the controller on unmount
    }, []);

    useEffect(() => {
        const updateHeight = () => {
          if (messageBarRef.current) {
            const calculatedHeight = messageBarRef.current.offsetHeight;
            console.log("Calculated height:", calculatedHeight);
            updateMessageBarHeight(calculatedHeight); // Assuming this function updates your state/context
          }
        };
      
        // Initial update on component mount or message change
        updateHeight();
      
        // Add resize event listener
        const handleResize = () => {
          requestAnimationFrame(updateHeight);
        };
      
        window.addEventListener('resize', handleResize);
      
        // Cleanup function to remove the event listener on component unmount
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, [message]); // Re-run when the message changes

    if (!message) return null; // Don't display if no messages

    return (
        <Box ref ={messageBarRef}>
            <Stack orientation="" component="div" sx={{background:"#363636", width:"calc(100% - 20px)", height:"auto", padding:"10px", color:"#fff", justifyContent:"center", alignItems:"center", textAlign:"center"}}>
                <Typography variant="body1" sx={{fontWeight:"600"}}>IMPORTANT NOTICE</Typography>
                <Typography variant="body2">{ReactHtmlParser(message)}</Typography>
            </Stack>
        </Box>
    );
}

export default memo(MessageBar);