import React, { memo } from "react";
import { Box } from '@mui/material';
import { styled } from '@mui/system';
import { CircularProgress } from "@mui/material";

const CenteredOverlay = styled(Box)(({ theme }) => ({
    position: 'fixed', // Fixed position to ensure it's in the viewport
    top: 0,
    left: 0,
    width: '100vw', // Full width
    height: '100vh', // Full height
    display: 'flex',
    justifyContent: 'center', // Horizontally center
    alignItems: 'center', // Vertically center
    background:'rgba(255,255,255,0.5)', // Optional: semi-transparent background
    zIndex: 9999, // Make sure it's on top of everything else
  }));

const LoadingOverlay = () => {
    return (       
        <CenteredOverlay>
            <CircularProgress />
        </CenteredOverlay>
    );
}

export default memo(LoadingOverlay);