import React, {Suspense, lazy} from "react";
import {BrowserRouter as Router, Route, Routes, useParams} from 'react-router-dom'
import { Experimental_CssVarsProvider as CssVarsProvider } from "@mui/material";
import theme from "./themes/default";
import LoadingOverlay from "./components/LoadingOverlay";
import MessageBar  from "./components/MessageBar";
import { MessageBarProvider } from "./context/MessageBarContext";
const Home = lazy(() => import('./pages/Home'));
const Aftermarket = lazy(() => import('./pages/Aftermarket'));
const Industrial = lazy(() => import('./pages/Industrial'));
const Engineering = lazy(() => import('./pages/Engineering'));
const EV = lazy(() => import('./pages/EV'));
const About = lazy(() => import('./pages/About'));
const Contact = lazy(() => import('./pages/Contact'));
const Category = lazy(() => import('./pages/Category'));
const Search = lazy(() => import('./pages/Search'));
const Dealers = lazy(() => import('./pages/Dealers'));
const Product = lazy(() => import('./pages/Product'));
const Support = lazy(() => import('./pages/Support'));
const PrivacyPolicy = lazy(() => import('./pages/PrivacyPolicy'));
const TermsandConditions = lazy(() => import('./pages/TermsandConditions'));

function App() {

  const IndustrySwitch = () => {
    const { industry } = useParams();
    return (   
        {
          'aftermarket': <Aftermarket />,
          'ev': <EV />,
          'industrial': <Industrial />,
          'engineering': <Engineering />
        }[industry]   
    );
  };

  return (    
    <CssVarsProvider theme = {theme}>  
      <MessageBarProvider>
        <MessageBar />
        <Router basename={'/'}>           
            <Routes>
              <Route path="/" element={<Suspense fallback={<LoadingOverlay/>}><Home /></Suspense>}/> 
              <Route path="/:industry" element={<Suspense fallback={<LoadingOverlay/>}><IndustrySwitch /></Suspense>}></Route>
              <Route path="/:industry/:cat" element={<Suspense fallback={<LoadingOverlay/>}><Category /></Suspense>}></Route>       
              <Route path="/:industry/:cat/:sub" element={<Suspense fallback={<LoadingOverlay/>}><Category /></Suspense>}></Route> 
              <Route path="/:industry/:cat/:sub/:subsub" element={<Suspense fallback={<LoadingOverlay/>}><Category /></Suspense>}></Route> 
              <Route path="/:industry/:cat/:sub/:subsub" element={<Suspense fallback={<LoadingOverlay/>}><Category /></Suspense>}></Route>  
              <Route path="/:industry/:cat/sku/:partno" element={<Suspense fallback={<LoadingOverlay/>}><Product /></Suspense>}></Route> 
              <Route path="/:industry/:cat/:sub/sku/:partno" element={<Suspense fallback={<LoadingOverlay/>}><Product /></Suspense>}></Route>  
              <Route path="/:industry/:cat/:sub/:subsub/sku/:partno" element={<Suspense fallback={<LoadingOverlay/>}><Product /></Suspense>}></Route> 

              {/* <Route path="/:industry/:cat/sku2/:partno" element={<Suspense fallback={<LoadingOverlay/>}><Product /></Suspense>}></Route> 
              <Route path="/:industry/:cat/:sub/sku2/:partno" element={<Suspense fallback={<LoadingOverlay/>}><Product /></Suspense>}></Route>  
              <Route path="/:industry/:cat/:sub/:subsub/sku2/:partno" element={<Suspense fallback={<LoadingOverlay/>}><Product /></Suspense>}></Route>  */}


              <Route path="/about" element={<Suspense fallback={<LoadingOverlay/>}><About /></Suspense>}></Route> 
              <Route path="/contact" element={<Suspense fallback={<LoadingOverlay/>}><Contact /></Suspense>}></Route> 
              <Route path="/:industry/search" element={<Suspense fallback={<LoadingOverlay/>}><Search /></Suspense>}></Route>
              <Route path="/:industry/dealers" element={<Suspense fallback={<LoadingOverlay/>}><Dealers /></Suspense>}></Route>
              <Route path="/:industry/support" element={<Suspense fallback={<LoadingOverlay/>}><Support /></Suspense>}></Route> 

              <Route path="/privacy-policy" element={<Suspense fallback={<LoadingOverlay/>}><PrivacyPolicy /></Suspense>}></Route> 
              <Route path="/terms-and-conditions" element={<Suspense fallback={<LoadingOverlay/>}><TermsandConditions /></Suspense>}></Route> 
            </Routes>
          </Router>
          </MessageBarProvider>
      </CssVarsProvider>
    
  );
}

export default App;
