import { Rotate90DegreesCcw } from "@mui/icons-material";
import { createTheme, experimental_extendTheme as extendTheme } from "@mui/material/styles";


const Theme = extendTheme({
    
    colorSchemes: {
        light: {
            palette:{
                
                primary:{
                    main: "#0057B8", 
                    light: "#145EBA",
                    dark: "#004AA6",       
                    contrastText:"#fff"
                },

                secondary:{
                    main: "#777777"
                },

                black:{
                    main: "#363636"
                },
                
                pagination: {
                    main:"#0057B8",
                    light:"#d8d8d8",
                    dark:"#f0f0f0",
                    contrastText: "#363636"
                },

                lightButton: {
                    main:"#f9f9f9",
                    light:"#f9f9f9",
                    dark:"#f2f2f2",
                    contrastText: "#555555"
                },

                darkButton:{
                    main:"#363636",
                    light:"#464646",
                    dark:"#262626",
                    contrastText: "#ffffff"
                },

                text:{
                    primary:"#363636",
                    secondary:"#777777",
                }
            }
        },
          
    },

    typography:{
        fontFamily:'Inter', 
        fontSize:9, 
    },
    
    transitions: {
        easing: {
            // This is the most common easing curve.
            easeInOut: 'cubic-bezier(0.4, 0, 0.2, 1)',
            // Objects enter the screen at full velocity from off-screen and
            // slowly decelerate to a resting point.
            easeOut: 'cubic-bezier(0.0, 0, 0.2, 1)',
            // Objects leave the screen at full velocity. They do not decelerate when off-screen.
            easeIn: 'cubic-bezier(0.4, 0, 1, 1)',
            // The sharp curve is used by objects that may return to the screen at any time.
            sharp: 'cubic-bezier(0.4, 0, 0.6, 1)',
        },
        duration: {
            enteringScreen: 200,
            leavingScreen: 200
        }
    },

    components: { 

        MuiTooltip:{
            styleOverrides:{
                tooltip:{
                    fontSize:12
                }
            }
        },

        MuiButton: {
            defaultProps: {
                disableRipple: true,
                disableElevation: true,
            },
            styleOverrides:{
                root:{
                    fontWeight:"bold",
                    textTransform:"capitalize",
                    padding:"4px 8px",
                    color:"#444",
                    "& .MuiButton-endIcon": {
                        position: "absolute",
                        right: "1rem"
                    },
    
                },

                text:{
                    "&:hover":{
                        background:"transparent",
                    },      
                },
                contained:{
                    background:"#363636",
                    color:"#ffffff",
                    padding:"8px 10px",
                    "&:hover":{                     
                        background:"#161616",
                        color:"#fff"
                    },          
                },
                // main: "#0057B8", 
                // light: "#145EBA",
                // dark: "#004AA6",   
                containedBlue:{
                    background:"#146BCC",
                    color:"#ffffff",
                    padding:"8px 10px",
                    "&:hover":{                     
                        background:"#0057B8",
                        color:"#fff"
                    },        
                },

                containedMobileBar:{
                    background:"#777",
                    color:"#363636",
                    padding:"8px 10px",
                    "&:hover":{                     
                        background:"red",
                        color:"#fff"
                    },     
                },
                outlinedPrimary: {     
                    border:"2px solid #363636",
                    padding:"5px 10px",
                    "&:hover":{
                        border: "2px solid #363636",
                        background:"#363636",
                        color:"#fff"
                    },                  
                },
                outlinedSecondary: {
                    border:"2px solid #0057B8",
                    color:"#0057B8",
                    "&:hover":{ 
                        border: "2px solid #0057B8",
                        background:"#0057B8",
                        color:"#fff"
                    },
                },
                outlinedHome: {
                    border:"2px solid #ffffff",
                    color:"#ffffff",
                    textTransform:"uppercase",
                    "&:hover":{
                        border: "2px solid #ffffff",
                        background:"#0057B8",
                        color:"#fff"
                    },
                }
            }
        },

        MuiSvgIcon:{
            styleOverrides:{
                root:{
                    cursor:"pointer"
                }
            }
        },

        MuiIconButton:{
            styleOverrides:{
                root:{
                    // "&:hover":{
                    //     background:"transparent"
                    // }
                }
            }
        },

        MuiToolbar: {
            styleOverrides: {
                dense: {
                    height: 70,
                    minHeight: 70
                }
            }
        },
        
        MuiButtonBase:{
            defaultProps:{
                disableRipple: true,
            },
            styleOverrides:{
                root:{
                    fontSize:'0.5rem',
                }
            }
        },

        MuiTabs:{
            defaultProps:{           
                TabIndicatorProps:{
                    style: {
                        display:'block',
                        background:"#0057B8",
                    }
                },        
            },
            styleOverrides:{
                root:{
                    fontWeight:"bold"
                }
            }
        },

        MuiTab:{           
            styleOverrides:{
                root:{
                    textTransform:"capitalize",
                    color:"#777",
                    fontWeight:"bold",
                    // border:"1px solid #e8e8e8",
                    // borderTopLeftRadius:"5px",
                    // borderTopRightRadius:"5px",
                    marginLeft:"10px",
                    "&.Mui-selected": {
                        color:"#363636"
                    },
                    '&:nth-of-type(1)': {
                        marginLeft: 0,
                    },
                }
            }
        },

        MuiTableRow:{
            styleOverrides:{
                root:{
                    '&:nth-of-type(odd)': {
                        backgroundColor: "transparent",
                        
                    },
                    '&:nth-of-type(even)': {
                        backgroundColor: "transparent",
                    },
                    borderBottom:"1px solid #f0f0f0",
                }
            } 
        },

        MuiTableCell:{
            styleOverrides:{
                root:{
                  //borderBottom:"1px solid #f2f2f2",
                  borderBottom:0,
                  padding:"15px 0"
                }
            } 
        },

        MuiTextField:{
            styleOverrides:{
                root:{
                    '& .MuiOutlinedInput-root': {
                        background:"#f9f9f9",

                        '& fieldset': {
                            border: '0', 
                            fontSize:'1rem',       
                        },
                        '&:hover fieldset': {
                            border: '0',
                        },
                        '&:hover': {
                            background: "#f2f2f2",
                        },
                        '&.Mui-focused': {
                            border: '0',
                            background:"#f2f2f2",
                        },
                    }
                }
             
            }
        },

        MuiSelect: {  
            styleOverrides: {
                root:{   
                    background:"#f9f9f9",
                    // Apply for mobile breakpoints (xs and sm)
                    "@media (max-width:600px)": {
                        background: "white", // Change background to white on mobile
                    },
                    '&& fieldset': {
                        border: '0'
                    },
                },
                '&.Mui-focused':{
                    background: "#f2f2f2",
                    '&& fieldset': {
                        border: '0'
                    }
                },          
            }
        },

        MuiDialog:{
            styleOverrides:{
                root:{
                    zIndex:'998'
                }
            }
        },

        MuiMenu:{
            defaultProps:{
                disablePortal:true,
            
            }
        },

        MuiMenuItem:{
            styleOverrides:{
                root:{
                    //padding:0,
                    '&:hover': {
                        // fontWeight:"bold !important",
                        // background:"#0057B8 !important",
                        // color:"#fff !important"
                    }
                }
            }
        },

        MuiListItemButton:{
            styleOverrides:{
                root:{
                    padding:"3px 0",
                   '&:hover':{
                        backgroundColor:"transparent !important",
                        color:"#000"
                   },
                   '&.Mui-selected':{
                        backgroundColor:"transparent !important",
                        color: "#000",
                   } 
                }
            }
        },

        MuiListItemText:{
            // primaryTypographyProps: {fontSize: '1em'},
            styleOverrides:{
                root:{
                    
                }
            }
        },

        MuiSlider:{
            styleOverrides:{
                root:{
                    "& .MuiSlider-thumb": {
                        height:"12px",
                        width:"12px",
                        background:"#2872C3", 
                        boxShadow:"none",
                        marginTop:0
                        // borderRadius:0, 
                        // borderTop: "7px solid transparent",
	                    // borderLeft: "13px solid #555",
	                    // borderBottom: "7px solid transparent",
                        // marginLeft:"6px",
                        
                        // '&:nth-of-type(even)': {
                        //     transform: "scaleX(-1)",   
                        //     marginTop:"-6px",
                        //     marginLeft:"-11px"
                        // }
                
                       
                    },  
                    "& .Mui-focusVisible": {
                        boxShadow: "none !important"
                    },
 

                        
                    
                    "& .MuiSlider-thumb:hover":{
                        boxShadow:"none"
                    },
                    "& .MuiSlider-track": { 
                        color:"#3C86D7", 
                        boxShadow:"none",
                        height:"4px"
                    }, 
                    "& .MuiSlider-rail": { 
                        color:"#adadad",
                        height:"6px"
                    },
                    '& .MuiSlider-active': {
                        color: "#f5e278"
                    }
                    
                }
            }
        },

        MuiDivider:{
            styleOverrides:{
                root:{
                    border:"1px solid #f2f2f2",
                }
            }
        }
    }
    
});


Theme.typography.h1 = {
    fontSize:"3.5rem",
    fontWeight:"bold",
    [Theme.breakpoints.down('lg')]:{
        fontSize:"3.4rem"
    },
    [Theme.breakpoints.down('md')]:{
        fontSize:"3.3rem"
    },
    [Theme.breakpoints.down('sm')]:{
        fontSize:"3.2rem"
    }
}

Theme.typography.h2 = {
    fontSize:"3.1rem",
    fontWeight:"bold",
    [Theme.breakpoints.down('lg')]:{
        fontSize:"3.0rem"
    },
    [Theme.breakpoints.down('md')]:{
        fontSize:"2.9rem"
    },
    [Theme.breakpoints.down('sm')]:{
        fontSize:"2.7rem"
    }
}


Theme.typography.h3 = {
    fontSize:"2.2rem",
    fontWeight:"bold",
    [Theme.breakpoints.down('lg')]:{
        fontSize:"2.1rem"
    },
    [Theme.breakpoints.down('md')]:{
        fontSize:"2rem"
    },
    [Theme.breakpoints.down('sm')]:{
        fontSize:"1.9rem"
    }
}

Theme.typography.h4 = {
    fontSize:"1.75rem",
    fontWeight:"bold",
    [Theme.breakpoints.down('lg')]:{
        fontSize:"1.7rem"
    },
    [Theme.breakpoints.down('md')]:{
        fontSize:"1.6rem"
    },
    [Theme.breakpoints.down('sm')]:{
        fontSize:"1.5rem"
    }
}

Theme.typography.h5 = {
    fontSize:"1.4rem",
    fontWeight:"bold",
    [Theme.breakpoints.down('lg')]:{
        fontSize:"1.3rem"
    },
    [Theme.breakpoints.down('md')]:{
        fontSize:"1.2rem"
    },
    [Theme.breakpoints.down('sm')]:{
        fontSize:"1.1rem"
    }
}

Theme.typography.h6 = {
    fontSize:"1.1rem",
    fontWeight:"bold",
    [Theme.breakpoints.down('lg')]:{
        fontSize:"1.0rem"
    },
    [Theme.breakpoints.down('md')]:{
        fontSize:"0.9rem"
    },
}

Theme.typography.subtitle1 = {
    fontSize:"0.8rem",
    [Theme.breakpoints.up('lg')]:{
        fontSize:"1.2rem"
    }
}

Theme.typography.subtitle2 = {
    fontSize:"0.8rem",
    [Theme.breakpoints.up('lg')]:{
        fontSize:"1rem"
    }
}

Theme.typography.body1 = {
    fontSize:"0.8rem",
    [Theme.breakpoints.up('lg')]:{
        fontSize:"0.9rem"
    }
}

Theme.typography.body2 = {
    fontSize:"0.75em",
    [Theme.breakpoints.up('sm')]:{
        fontSize:"0.7rem"
    },
    [Theme.breakpoints.up('md')]:{
        fontSize:"0.8rem"
    },
}

Theme.typography.small = {
    fontSize:"0.65em",
    [Theme.breakpoints.up('sm')]:{
        fontSize:"0.7rem"
    }
}

Theme.typography.button = {
    fontSize:"0.7rem",
    [Theme.breakpoints.up('md')]:{
        fontSize:"0.75rem"
    },
    [Theme.breakpoints.up('xl')]:{
        fontSize:"0.8rem"
    }
}

export default Theme;